import { createAsyncThunk } from "@reduxjs/toolkit"

import { GET } from "lib/core/api"
import { serializeError } from "lib/core/redux-tools.utils"
import { FullNote } from "lib/leeruniek/interfaces"

export interface FetchNotesArgs {
  schoolId: number
  yearclassId: number
}

export const fetchNotes = createAsyncThunk<FullNote[], FetchNotesArgs>(
  "NOTES_FETCH",
  async ({ schoolId, yearclassId }: FetchNotesArgs) => {
    const result = await GET("/all_notes", {
      query: { schoolId, yearclassId },
    })

    return result as FullNote[]
  },
  { serializeError },
)
