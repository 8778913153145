import { createReducer } from "@reduxjs/toolkit"

import { FullNote } from "lib/leeruniek/interfaces"
import { fetchNotes } from "entities/group__notes.page/group__notes.actions"

type State = {
  notes: FullNote[]
  isNotesLoading: boolean
}

type GetNotesResponse = {
  payload: FullNote[]
}

const initialState: State = {
  notes: [],
  isNotesLoading: false,
}

export default createReducer(initialState, (builder) => {
  builder
    .addCase(
      fetchNotes.pending,
      (state: State): State => ({
        ...state,
        isNotesLoading: true,
      }),
    )
    .addCase(
      fetchNotes.fulfilled,
      (state: State, action: GetNotesResponse): State => ({
        ...state,
        isNotesLoading: false,
        notes: action.payload,
      }),
    )
    .addCase(
      fetchNotes.rejected,
      (state: State): State => ({
        ...state,
        isNotesLoading: false,
      }),
    )
})
